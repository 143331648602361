<template>
  <k-layout>
    <header class="tw-bg-white tw-px-4">
      <div class="tw-relative tw-mx-auto tw-w-full tw-max-w-xl lg:tw-max-w-screen-lg tw-py-12 md:tw-py-20">
        <img
          src="./img/referral.svg"
          class="tw-absolute tw-bottom-0 tw-right-0 tw-max-h-96 tw-hidden lg:tw-block tw-pointer-events-none"
        />

        <k-product-title title="Referral" />

        <h2 class="tw-mt-8 tw-heading-2" v-html="$t('referral.subtitles.colleague_planning')" />

        <p class="tw-max-w-lg tw-mt-8">
          {{ $t('referral.descriptions.recommend_projects') }}
        </p>

        <div class="tw-mt-8 tw-flex tw-space-x-4">
          <k-link :to="{ name: 'referral-form' }" type="other">
            <k-button>
              {{ $t('global.actions.recommend') }}
            </k-button>
          </k-link>

          <k-button color="secondary" @click="$vuetify.goTo('#referral_how-does-it-work')">
            {{ $t('referral.subtitles.how_it_works') }}
          </k-button>
        </div>
      </div>
    </header>

    <div id="referral_how-does-it-work">
      <div class="tw-mx-auto tw-w-full tw-max-w-xl lg:tw-max-w-screen-lg tw-py-12 md:tw-py-20">
        <div class="tw-flex tw-flex-col tw-items-center tw-px-4">
          <h2 class="tw-heading-1">{{ $t('referral.subtitles.how_it_works') }}</h2>
          <p class="mt-6 tw-text-xl tw-max-w-4xl tw-text-center" v-html="$t('referral.descriptions.friend_looking')" />
        </div>

        <div class="tw-grid tw-grid-cols-1 tw-px-4 sm:tw-grid-cols-3 tw-gap-8 tw-mt-12">
          <div>
            <div class="tw-flex tw-items-center tw-flex-col">
              <img src="./icons/files-and-folders.svg" alt="Krok 1" />
              <h3 class="tw-heading-4 tw-mt-4">{{ $t('global.step') }} 1</h3>
              <p class="tw-text-sm tw-mt-4 tw-text-center">
                {{ $t('referral.descriptions.fill_short_form') }}
              </p>
            </div>
          </div>
          <div>
            <div class="tw-flex tw-items-center tw-flex-col">
              <img src="./icons/interview.svg" alt="Krok 2" />
              <h3 class="tw-heading-4 tw-mt-4">{{ $t('global.step') }} 2</h3>
              <p class="tw-text-sm tw-mt-4 tw-text-center">
                {{ $t('referral.descriptions.interview_with_you') }}
              </p>
            </div>
          </div>
          <div>
            <div class="tw-flex tw-items-center tw-flex-col">
              <img src="./icons/tick.svg" alt="Krok 3" />
              <h3 class="tw-heading-4 tw-mt-4">{{ $t('global.step') }} 3</h3>
              <p class="tw-text-sm tw-mt-4 tw-text-center">
                {{ $t('referral.descriptions.concluding_contract') }}
              </p>
            </div>
          </div>
        </div>

        <div class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-3 tw-mt-18">
          <div class="tw-bg-white tw-p-4 md:tw-p-8">
            <div class="tw-flex tw-flex-col">
              <h3 class="tw-heading-1">{{ $t('referral.subtitles.forms_of_payment') }}</h3>
              <p class="tw-text-lg tw-mt-4">
                {{ $t('referral.descriptions.fill_short_form') }}
              </p>
            </div>
          </div>
          <div class="tw-bg-koderia tw-text-gray-50 tw-p-4 md:tw-p-8">
            <div class="tw-flex tw-flex-col">
              <h3 class="tw-heading-1">1 000 &euro;
                <span class="tw-font-normal">{{ $t('referral.subtitles.one_time_commission') }}</span></h3>
            </div>
          </div>
          <div class="tw-bg-koderia-alt tw-text-gray-50 tw-p-4 md:tw-p-8">
            <div class="tw-flex tw-flex-col">
              <h3 class="tw-heading-1">5 &euro; <span class="tw-font-normal">{{ $t('referral.subtitles.worked_day')
                }}</span></h3>
            </div>
          </div>
        </div>

        <p class="tw-mt-6 tw-px-4 tw-text-xs">
          {{ $t('referral.descriptions.one_time_commission') }}
        </p>

        <div class="tw-border-t tw-border-gray-200 tw-py-16 tw-mt-16 tw-px-4">
          <div class="tw-flex tw-items-center tw-flex-col">
            <h2 class="tw-heading-1 tw-text-center md:tw-text-left">{{ $t('referral.subtitles.ready_to_collaborate')
              }}</h2>
            <div class="mt-10">
              <k-link
                id="ga_referral_initiate"
                :to="{ name: 'referral-form' }"
              >
                <k-button>
                  {{ $t('global.actions.continue') }}
                </k-button>
              </k-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </k-layout>
</template>
<script>
import KButton from '@web/components/core/k-button'

export default {
  components: { KButton }
}
</script>
